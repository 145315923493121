<template>
  <div class="speakers_v" :data-version="(global.year&&global.year.slice(0,4))||'2022'">
    <div v-for="(item, index) in speakers" class="speakers_con act" :class="index" :key="'speakers_v_' + index">
      <h1 class="head_title">
        {{ item.title }}
      </h1>
      <p class="head_title">
        {{ item.desc }}
      </p>
      <div class="list" v-if="item.list.length>0">
        <div
          class="citem"
          v-for="(litem, lindex) in item.list"
          :key="'giac2022_home_chairman_' + index + '_' + lindex"
        >
          <div class="box">
            <router-link :to="{path:`/${global.year}/teacher`, query:{id:litem.id}}">
              <div class="img">
                <span></span>
                <div>
                  <!-- 懒加载 -->
                  <!-- <img v-lazy="setImg(litem.thumbs)" alt="" /> -->
                  <img v-lazy="litem.thumbs" alt="" />
                  <!-- <img v-lazy="global.getSrc(litem.thumbs)" alt="" /> -->
                  <p>查看详情</p>
                </div>
              </div>
              <h3>{{ litem.name }}</h3>
              <p>{{ litem.company }} {{ litem.position }}</p>
            </router-link>
          </div>
        </div>
      </div>
      <p class="nolist" v-else>敬请期待</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Speakers",
  props: {
    _speakers: Object
  },
  data() {
    return {
      // 联席主席 出品人 选题委员会 演讲嘉宾
      speakers: {},
      scrollinit1: false,
      scrollinit2: false,
      scrollinit3: false,
      scrollinit4: false,
    };
  },
  mounted() {
    // console.log(this._speakers);
    this.speakers = {...this._speakers};
    this.speakerScroll();
  },
  methods: {
    setImg(_src) {
      let src = _src;
        // _src && _src.indexOf("http") > -1
        //   ? _src.split("?image")[0] + "?imageView2/1/w/220/h/241/q/75"
        //   : "";
      return src;
    },
    speakerScroll() {
      let _this = this;
      window.onscroll = function () {
        let sheight = window.innerHeight;
        let scrolltop = document.documentElement.scrollTop;
        // console.log("《《《 ", scrolltop, sheight);
        // console.log(_this.scrollinit1, _this.scrollinit2, _this.scrollinit3, _this.scrollinit4);
        // console.log($(".speakers_con.chairman").offset().top-scrolltop, sheight*2/3);
        if($(".speakers_con.chairman").offset() && !_this.scrollinit1){
          let top_chairman = $(".speakers_con.chairman").offset().top;
          if((top_chairman<sheight*2/3 && scrolltop<sheight*2/3) || (scrolltop<=top_chairman && (top_chairman-scrolltop)<sheight*2/3)){
            $(".speakers_con").removeClass("act");
            $(".speakers_con.chairman").addClass("act");
            _this.scrollinit1 = true;
          }
          // console.log(top_chairman);
        }
        if($(".speakers_con.producer").offset() && !_this.scrollinit2){
          let top_producer = $(".speakers_con.producer").offset().top;
          if((top_producer<sheight*2/3 && scrolltop<sheight*2/3) || (scrolltop<=top_producer && (top_producer-scrolltop)<sheight*2/3)){
            $(".speakers_con").removeClass("act");
            $(".speakers_con.producer").addClass("act");
            _this.scrollinit2 = true;
          }
          // console.log(top_producer);
        }
        if($(".speakers_con.selection").offset() && !_this.scrollinit3){
          let top_selection = $(".speakers_con.selection").offset().top;
          if((top_selection<sheight*2/3 && scrolltop<sheight*2/3) || (scrolltop<=top_selection && (top_selection-scrolltop)<sheight*2/3)){
            $(".speakers_con").removeClass("act");
            $(".speakers_con.selection").addClass("act");
            _this.scrollinit3 = true;
          }
          // console.log(top_selection);
        }
        if($(".speakers_con.speaker").offset() && !_this.scrollinit4){
          let top_speaker = $(".speakers_con.speaker").offset().top;
          if((top_speaker<sheight*2/3 && scrolltop<sheight*2/3) || (scrolltop<=top_speaker && (top_speaker-scrolltop)<sheight*2/3)){
            $(".speakers_con").removeClass("act");
            $(".speakers_con.speaker").addClass("act");
            _this.scrollinit4 = true;
          }
          // console.log(top_speaker);
        }
        _this.$forceUpdate();
      };
    },
  }
};
</script>

<style lang="scss">
.speakers_v {
  width: 100%;
  .speakers_con {
    .head_title {
      width: 100%;
      text-align: center;
    }
    h1.head_title:nth-of-type(1) {
      margin-top: 75px;
    }
    h1.head_title:not(:nth-of-type(1)) {
      margin-top: 60px;
    }
    h1.head_title {
      height: 32px;
      line-height: 32px;
      font-size: 32px;
      font-family: PingFang SC Bold;
      font-weight: bold;
      color: #00081a;
    }
    p.head_title {
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      font-family: PingFang SC Regular;
      font-weight: 400;
      color: #777777;
      margin-top: 18px;
    }
    div.list {
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .citem {
        .box {
          // width: fit-content;
          padding: 0 58px;
          margin-bottom: 55px;
          a, a:hover, a:focus, a:active, a:visited {
            color: initial;
            text-decoration: none;
          }
          a {
            .img {
              width: 212px;
              height: 212px;
              padding: 1px;
              margin: 0 auto;
              border-radius: 50%;
              // border: 1px solid;
              // border-image: linear-gradient(0deg, #54BDB0, #3C7760) 10 10;
              background: linear-gradient(0deg, #54bdb0, #3c7760);
              position: relative;
              cursor: pointer;
              span {
                width: 14px;
                height: 14px;
                top: 22px;
                right: 27px;
                background: linear-gradient(140deg, #54bdb0 0%, #3c7760 99%);
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                z-index: 5;
              }
              div {
                width: 100%;
                height: 100%;
                background: #fbfbfb;
                border-radius: 50%;
                padding: 9px;
                overflow: hidden;
                position: relative;
                z-index: 1;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                }
                p {
                  width: 210px;
                  height: 210px;
                  line-height: 192px;
                  font-size: 20px;
                  font-family: PingFang SC Regular;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: center;
                  border-radius: 50%;
                  top: 0;
                  left: 0;
                  z-index: 3;
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.35);
                  background-clip: padding-box;
                  border: 9px solid transparent;
                  opacity: 0;
                  transition: opacity 0.25s ease;
                }
                p:hover {
                  opacity: 1;
                }
              }
            }
            > h3 {
              width: 100%;
              height: 24px;
              line-height: 24px;
              font-size: 25px;
              font-family: PingFang SC Medium;
              font-weight: bold;
              color: #333333;
              text-align: center;
              margin-top: 35px;
            }
            > p {
              width: 100%;
              max-width: 212px;
              margin: 0 auto;
              margin-top: 16px;
              line-height: 26px;
              font-size: 18px;
              font-family: Alibaba PuHuiTi Regular;
              font-weight: 400;
              color: #666666;
              text-align: center;
            }
          }
        }
      }
    }
    p.nolist {
      margin-top: 60px;
      margin-bottom: 55px;
      font-size: 24px;
      font-family: PingFang SC Medium;
      font-weight: 500;
      color: #2630b2;
      text-align: center;
    }
  }
  @keyframes clockwise {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
  @keyframes anticlockwise {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
  }
  .speakers_con.act div.list .citem .box a .img {
    animation: clockwise 2s ease;
    div {
      animation: anticlockwise 2s ease;
    }
  }
}
@media (max-width: 767px) {
  .speakers_v {
    .speakers_con {
      h1.head_title {
        height: 22px;
        line-height: 22px;
        font-size: 22px;
      }
      p.head_title {
        margin-top: 8px;
        min-height: 14px;
        line-height: 16px;
        font-size: 14px;
      }
      h1.head_title:nth-of-type(1) {
        margin-top: 35px;
      }
      h1.head_title:nth-of-type(2) {
        margin-top: 15px;
      }
      div.list {
        margin-top: 20px;
        .citem {
          // width: 33.33%;
          .box {
            width: 122px;
            padding: 0 15px;
            margin: 0 auto;
            margin-bottom: 20px;
            a {
              .img {
                width: 92px;
                height: 92px;
                span {
                  width: 6px;
                  height: 6px;
                  top: 10px;
                  right: 11px;
                }
                div {
                  padding: 4px;
                }
              }
              h3 {
                margin-top: 15px;
                height: auto;
                line-height: 20px;
                font-size: 16px;
              }
              p {
                max-width: 92px;
                margin-top: 5px;
                line-height: 20px;
                font-size: 12px;
              }
            }
          }
        }
      }
      p.nolist {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
  }
}
</style>